.redes-sociais{

	& > *{
		font-size:18px;
		color:#FFF;
		@include margin(0,10);
		position:relative;

		&:after{
			content:"";
			display:block;
			position:absolute;
			width:5px;
			height:5px;
			border-radius:100%;
			opacity:0;
			left:calc(50% - 2px);
			top:calc(50% - 2px);
			background-color:#FFF;
			transform:scale(0);
			transition:all 0.6s linear;
		}

		&:hover{
			color:#FFF;

			&:after{
				animation:explodirmedia 1.2s infinite;
			}

		}

	}

	&.contato__redes-sociais{

		& > *{
			color:inherit;
		}

	}

}