.texto__privacidade__desc{
	text-align: justify;
	margin-bottom: 50px;
}
.titulo__form{
	font-size: 18px;
	line-height: 22px;
	color: #333333;
	font-family: 'montserratextrabold';
	font-weight: normal;
	margin-bottom: 15px;
}
.privacidade__form{
	.form-group{
		margin-bottom: 10px;
	}

	.form-control{
		border-radius: 16px;
		background-color: #d1d4df;
		font-family: 'montserratbold';
		font-size: 16px;
		line-height: 16px;
		color: #333333;
		padding: 14px 20px;
		display: block;
		width: 100%;

		&::placeholder{
			color: #333333;
		}
	}
}
.titulo__checks{
	margin-bottom: 10px;
	font-size: 15px;
	line-height: 19px;
	color: #333333;
	font-family: 'montserratextrabold';
	font-weight: normal;
	margin-bottom: 15px;
}
.segura__check{
	padding-left: 10px;
	padding-top: 10px;
}
.custom__check{
	color: #333333;
	margin-bottom: 2px;

	.custom__check__input{
		position: fixed;
		left: -200vw;

		&:checked{
			~ .custom__check__label{
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.custom__check__label{
		position: relative;
		padding-left: 30px;
		cursor: pointer;

		a{
			font-family: 'montserratextrabold';
			text-decoration: underline;
		}

		&:before{
			position: absolute;
			content: '';
			width: 18px;
			height: 18px;
			border: 2px solid #333333;
			border-radius: 50%;
			left: 0px;
			top: calc(50% - 9px);
		}
		&:after{
			position: absolute;
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: #333333;
			left: 4px;
			top: calc(50% - 5px);
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
}
.segura__check__confirma{
	padding-top: 30px;
	padding-left: 10px;
	font-family: 'montserratextrabold';
}
.segura__botao__check{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 40px;
}
