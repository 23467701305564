.rodape{

	.texto__rodape__center{
		text-align: center;
		margin-top: 50px;
	}

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.rodape__contato{
		background-color:$theme;
		padding-top:12px;
		padding-bottom:50px;
		text-align:center;
		position:relative;
		z-index:0;

		@include media-breakpoint-up(lg){
			padding-top:2px;

			&:before{
				content:"";
				display:block;
				width:100%;
				height:576px;
				position:absolute;
				left:0;
				bottom:0;
				background-image:url("../images/bg-rodape.jpg");
				background-repeat:no-repeat;
				background-position:center center;
				z-index:-2;
			}

		}

		.row > *{

			@include media-breakpoint-down(md){
				margin-bottom:20px;
			}

		}

	}

	.rodape__copyright{
		text-align:center;
		background-color:$theme2;
		color:#FFF;
		padding-top:10px;
		padding-bottom:11px;
		font-size:12px;

		span{
			font-family: 'montserratbold';
		}

		.gv8 img{
			max-width:38px;
			margin-left:6px;
		}

	}

	&.investimentos{


		.rodape__contato{

			@include media-breakpoint-up(lg){

				&:before{
					background-image:url('../images/bg-rodape-investimentos.jpg');
				}

			}

		}

	}

}
.msg__cookies{
	background: #FFF;
	position: fixed;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	padding: 15px;
	border-radius: 15px;
	box-shadow: 0 0 10px rgba(#000,.8);
	width: 100%;
	z-index: 999999999;
	border: 1px solid rgba(#000,.2);

  &.d-none{
    display: none;
  }

	a{
		color: #333333;
		text-decoration: underline;
	}

	.btn{
		background-color: transparent;

		&:before{
			z-index: -1;
		}
	}

	@media (max-width: 767px){
		max-width: calc(100% - 30px);
		font-size: 12px;
		text-align: center;

		a{
			display: inline-block;
		}

		.content{
			margin-bottom: 10px;
		}
	}

  @media (min-width: 767px){
		display: grid;
		grid-template-columns: 1fr min-content;
		grid-column-gap: 10px;
		align-items: center;

		.btn{
			white-space: nowrap;
		}
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		max-width: 768px;
	}

  @media (min-width: 992px) and (max-width: 1199.98px) {
		max-width: 1024px;
	}

  @media (min-width: 1200px) {
		max-width: 1280px;
	}
}
