.card-conceito{
	max-width:260px;
	margin-right:auto;
	margin-left:auto;
	margin-bottom:46px;

	.card-conceito__figure{
		position:relative;
		color:#FFF;
		overflow:hidden;

		.card-conceito__absolute-lado,
		.card-conceito__absolute-content{
			position:absolute;
		}

		.card-conceito__absolute-content{
			background-color:transparent;
			z-index:20;
			right:15px;
			bottom:40px;
			text-align:right;
			max-width:180px;

			@include beforeAfter{
				content:'';
				display:block;
				width:10px;
				height:10px;
				position:absolute;
				right:-6px;
				border-right:3px solid #D1D4DF;
			}

			&:before{
				top:-13px;
				border-top:3px solid #D1D4DF;
			}

			&:after{
				bottom:-13px;
				border-bottom:3px solid #D1D4DF;
			}

			& > *{
				line-height:1;
			}

		}

		.card-conceito__absolute-lado{
			left:0;
			bottom:0;
			transform-origin:0 0;
			width:calc(100% + 91px);
			z-index:40;

			@include media-breakpoint-down(md){
				min-width:380px;
			}

			.card-conceito__absolute-lado-box{
				background-color:rgba($theme2,0.7);
				padding:10px 16px;
				text-align:right;
				font-size:16px;
				font-family: 'montserratsemibold';
			}

		}

		.card-conceito__subtitle,
		.card-conceito__title{
			margin-bottom:4px;
		}

		.card-conceito__subtitle{
			font-size:16px;
			word-break:break-all;
		}

		.card-conceito__title{
			font-size:24px;
			font-family: 'montserratbold';
			line-height:1;
			word-break:break-all;
		}

		.card-conceito__desc{
			font-size:13px;
			line-height:1.2;
		}

	}

	&.card-conceito--internas{
		max-width:350px;
		margin-bottom:28px;

		.card-conceito__figure{

			.card-conceito__absolute-lado{
				width:100%;
			}

		}

		.card-conceito__absolute-content{
			padding-left:30px;
			padding-right:30px;

			.card-conceito__title{
				font-size:16px;
				font-family: 'montserratbold';
			}

			.card-conceito__desc{
				margin-bottom:12px;
			}

		}

	}

}