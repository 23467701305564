.carousel-indicators-custom{
	margin-bottom:19px;
	justify-content:flex-end;
	@include make-container-max-widths();
	width:100%;
	margin-left:auto;
	margin-right:auto;

	@include media-breakpoint-down(sm){
		justify-content:center;
	}

	li{
		width:34px;
		height:8px;
		@include margin(0,8);
		opacity:1;
		transition:background-color 0.6s linear;

		&.active{
			background-color:$theme;
		}

	}

}