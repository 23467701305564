.menu-topo{
	list-style-type:none;
	margin-bottom:20px;
	padding-left:0;
	font-size:13px;

	@include media-breakpoint-up(xl){
		margin-bottom:0;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		padding-left:32px;
	}

	li{
		flex-grow:1;

		a{
			position:relative;

			@include media-breakpoint-down(lg){
				display:block;
				padding:12px 15px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

			@include media-breakpoint-up(xl){
				padding:32px 2px;
				display:inline-block;

				&:before{
					content:"";
					display:block;
					width:0;
					transition:width 0.6s linear;
					height:8px;
					position:absolute;
					bottom:0;
					left:0;
					background-color:$theme;
				}

			}

		}

		&.active,
		&:hover{

			a{

				@include media-breakpoint-down(lg){
					background-color:$theme;
					color:#FFF;
					border-color:darken($theme,20%);
				}

				&:before{
					width:100%;
				}

			}

		}

	}

}