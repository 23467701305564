.banner{

	.carousel-item{
		margin-bottom:0;
	}

}

.sobre{
	background-color:$theme;
	padding-top:88px;
	padding-bottom:70px;
	text-align:right;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:calc(0% - 25vw) center;
	background-attachment:fixed;

	@include media-breakpoint-down(md){
		background-image:none !important;
		text-align:center;
	}

	.sobre__desc{
		min-height:134px;
	}

}

.vantagens{
	background-color:$theme2;
	color:#FFF;
	padding-top:58px;
	padding-bottom:40px;
	background-repeat:no-repeat;
	background-position:right;
	background-attachment:fixed;
}

.conceito{
	background-color:$theme;
	padding-top:86px;
	padding-bottom:30px;
	text-align:center;

	.row{
		position:relative;
		z-index:50;
	}

}