.paginacao{
	display: flex;
	flex-wrap: wrap;

	&.paginacao__start{
		justify-content: flex-start;
	}

	&.paginacao__center{
		justify-content: center;
	}

	&.paginacao__end{
		justify-content: flex-end;
	}

	&.paginacao__between{
		justify-content: space-between;
	}

	.page__item {
		display: inline-flex;
		align-items:center;
		justify-content:center;
		flex-wrap:wrap;
		font-family: 'montserratsemibold';
		font-size:15px;
		color:$theme2;
		width:2em;
		height:2em;
		text-align:center;
		line-height:2em;
		border: 2px solid currentColor;
		color: inherit;
		padding:0;
		@include margin(0,4);

	}

	.page__item--active{
		background-color:$theme2;
		color:#FFF;
		border-color:$theme2;
	}

}
