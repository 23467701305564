.form-contato{

	form{
		max-width:444px;
		margin-right:auto;
		margin-left:auto;

		.form-group,
		.input-group{
			margin-bottom:12px;

			.form-control{
				min-height:34px;
				border-radius:0;
				background-color:#FFF;
				border-color:#FFF;
				font-size:12px;
				@include place(#393A3B);
				@include sombra(darken(#FFF,20%));
			}

			textarea.form-control{
				padding-top:14px;
				resize:none;
			}

		}

	}

}