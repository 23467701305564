.article-vantagens{
	margin-bottom:8px;
	position:relative;
	padding-top:48px;
	padding-left:48px;

	.article-vantagens__number{
		font-size:140px;
		font-family: 'montserratblack';
		line-height:1;
		opacity:0.06;
		position:absolute;
		left:0;
		top:0;
	}

	.article-vantagens__title{
		font-size:20px;
		font-family: 'montserratbold';
		margin-bottom:4px;
	}

	.article-vantagens__desc{
	}

}