@import "_home.scss";
@import "_privacidade.scss";

.internas{
	position:relative;
	z-index:400;

	@include media-breakpoint-up(lg){
		padding-bottom:70px;
	}

	&.internas-investimento{

		@include media-breakpoint-up(lg){
			padding-bottom:62px;
		}

	}

	&.internas-sobre{

		.equipe{
			background-color:#fff;
			padding-left:20px;
			padding-right:20px;
			margin-bottom:50px;

			.equipe__unidade{
				background-color:$theme2;
				color:#FFF;
				display:flex;
				align-items:center;
				justify-content:flex-start;
				font-size:22px;
				margin-bottom:40px;
				padding:12px 24px;
				margin-left:-20px;
				margin-right:-20px;

				.equipe-unidade__title{
					line-height:1;
				}

			}

		}

	}

}

.page-header{
	font-family: 'Roboto', sans-serif;
	background: #f0f0f0;
	padding-top:40px;
	padding-bottom:40px;
	text-align: center;
	margin-bottom: 60px;

	.breadcrumb{
		padding:0 30px 15px 30px;
		min-width: 250px;
		max-width: 100%;
		background: none;
		display: inline-flex;
		justify-content:center;
		margin-bottom: 0;
		border-radius:0;
		border-bottom: 2px solid rgba(#000,0.2);
		position: relative;

		span{
			color: #888;
			cursor: default;
		}

		&:before{
			content:'';
			position: absolute;
			left:50%;
			bottom:-2px;
			transform: translateX(-50%);
			height: 5px;
			width: 60px;
			background: darken($theme,10%);
		}

		li + li:before{
			content:'/';
			display: inline-block;
			margin:0 10px;
		}
	}

	h1{
		font-family: 'Roboto', sans-serif;
		font-weight: 900
	}

	.detail{
		border: 1px solid currentcolor;
		border-top: none !important;
	}

	.subtitle{
		font-size: 20px;
		// margin-bottom:5px;
	}

	.detail-title{
		width: 40px;
		height:3px;
		position: relative;
		font-size: 20px;


		&:before,
		&:after{
			content:'';
			display:block;
			position:absolute;
			width: calc(50% - 2px);
			height:100%;
		}

		&:before{
			left:0;
			background: #FFF;
		}

		&:after{
			right:0;
			background: #FF002E;
		}
	}

}

svg.detail-titles{
	display:inline-block;
	vertical-align: middle;
	border:none;

	use{
		fill: currentcolor;
	}
}

.paginacao{
	margin-bottom: 60px;
	text-align:center;
	color:#333;

	& > *{
		display: inline-block;
		padding:0.3em 0.6em;
		border:2px solid #333;


		& + *{
			margin-left:0.3em;
		}
	}

	.active{
		background: #333;
		color: #FFF;
	}

	a:hover{
		background: rgba(#000,0.1);
		text-decoration: none;
	}
}

.md-form{
	position:relative;
	padding-top:1.5rem;
	padding-bottom:1rem;

	& > label{
		margin:0;
		padding:0.375rem 0.75rem;
		position:absolute;

		@include media-breakpoint-down(sm){
			top:0;
			padding:0;
		}

		@include media-breakpoint-up(md){
			top:1.5rem;
			transition:all 0.3s linear;
		}
	}

	.form-control{
		border-radius:0;

		&.form-control-lg ~ label{
			padding: 0.5rem 1rem;
			font-size:1.25rem;
		}

		&.form-control-sm ~ label{
			padding: 0.25rem 0.5rem;
			font-size:0.875rem;
		}
	}

	.hover-eff{
		width:0;
		height:2px;
		background:$green;
		transition:width 0.3s linear;
	}

	.form-control:focus ~ .hover-eff,
	&.active .hover-eff{
		width: 100%;
	}

	&.has-success{
		.hover-eff{
			background: materialColor('green','green');
		}

		label{
			color: materialColor('green','darken-2') !important;
		}
	}

	&.has-error{
		.hover-eff{
			background: materialColor('red','red');
		}

		label{
			color: materialColor('red','red') !important;
		}
	}

	&.has-warning{
		.hover-eff{
			background: materialColor('orange','darken-2');
		}

		label{
			color: materialColor('orange','darken-2') !important;
		}
	}

	@include media-breakpoint-up(md) {
		&.active {
			& > label,
			.form-control-lg ~ label,
			.form-control-sm ~ label{
				top:0;
				padding-top:0;
				font-size: 0.8rem;
			}
		}
	}
}

textarea.form-control{
	height:auto !important;
}

.select-custom{
	position:relative;

	output{
		display: block;
	}

	.drop-options{
		background: #FFF;
		padding: 0.75rem;
		border:1px solid rgba(#000,0.2);
		position:absolute;
		left:0;
		top:100%;
		width:100%;
		display: none;
		z-index:5;
	}

	input[disabled]{
		background: #FFF;
	}

	&.open{
		.drop-options{
			display: block;
		}
	}

	input[name="filtro"]{
		@extend .form-control;
		margin-bottom:10px;
	}

	ul{
		list-style:none;
		padding-left:0;
		margin-left:-0.75rem;
		margin-right:-0.75rem;
		margin-bottom:0;
		max-height: 120px;
		overflow: auto;

		label{
			padding:0.5rem 0.75rem;
			display: block;
			margin-bottom:0;

			&:hover{
				background: rgba(#000,0.1);
			}
		}

		input{
			position:absolute;
			display: none;

			&:checked ~ label{
				background: $blue;
				color: #fff;
			}
		}
	}
}

#alertas{
	position: fixed;
	right:15px;
	top:90px;
	width: 350px;
	max-width: calc(100% - 30px);
	z-index: 999;

	.alert{
		border-radius: 0;
		border-color: rgba(#000,0.2);
		@extend .alert-dismissible;
		box-shadow:0 0 8px rgba(#000,.4);
		animation: rubberBand 0.6s linear;

		&.alert-success{
			background: materialColor('green', 'darken-2');
			color: #FFF;
		}

		&.alert-danger{
			background: materialColor('red', 'darken-2');
			color: #FFF;
		}

		&.alert-warning{
			background: materialColor('orange', 'darken-2');
			color: #FFF;
		}

		&.alert-info{
			background: materialColor('blue', 'darken-2');
			color: #FFF;
		}
	}

	.media-body{
		align-self: center;
	}

	.icon-alerta{
		font-size: 2em;
		margin-right: 10px;
	}

	.alert-success{}
}

#modelo-3.empresa{
	.title{
		color: darken($theme,20%);
		font-weight: 900;
	}

	#carousel-empresa{
		width:850px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		.carousel-wrapper{
			position: relative;
			margin-bottom:10px;
		}

		.next,
		.prev{
			position: absolute;
			width: 40px;
			height: 40px;
			top: calc(50% - 20px);
			background: #FFF;
			border-radius: 100%;
			display: flex;
			align-items:center;
			justify-content:center;
			border:1px solid rgba(#000,0.2);
			box-shadow: 0 0 5px rgba(#000,0.4);
			text-decoration: none;

			&:hover{
				background: #eee;
			}
		}

		.next{
			right:15px;
		}

		.prev{
			left:15px;
		}

		.carousel-indicators{
			position: static;
			margin-bottom: 0;
			justify-content: flex-start;
			width:100%;
			margin-left:0;
			margin-right:0;
			overflow: auto;

			li{
				width: calc(50% - 15px);
				min-width: calc(50% - 15px);
				height: auto;
				text-indent: 0;
				background: #FFF;
				margin:0;

				& + li{
					margin-left: 30px;
				}
			}
		}
	}

	hr{
		border-color: rgba(#000,0.2);
		border-width: 2px;
		margin-bottom: 50px;
	}

	.missao-visao-valores{
		text-align:center;

		.lazyimage{
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;
		}

		.titulo{
			font-size: 20px;
			margin-bottom: 10px;
			padding-top:15px;

			span{
				font-weight: 900;
				color: darken($theme,20%);
			}
		}
	}

	.depoimento{
		text-align: center;
		padding-top: 40px;
		padding-bottom: 40px;
		background: $theme;

		.fas.fa-quote-left{
			font-size: 50px;
			margin-bottom: 15px;
		}

		h2{
			font-weight: 300;
			font-style: italic;
		}

		.autor{
			margin-top:30px;
		}

	}

	.autor{
		.avatar{
			width: 80px;
			height: 80px;
			border-radius: 100%;
			border:2px solid #FFF;
			overflow: hidden;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}

		.nome{
			font-weight: bold;
		}

		.cargo{
			font-size: 13px;
		}

	}
}
.quem__somos__custom{
	padding: 76px 0px 110px 0px;
	overflow: hidden;
}
h1.titulo__interna__quem{
	font-size: 30px;
	line-height: 34px;
	color: #333333;
	font-family: 'montserratregular';
	font-weight: normal;
	margin-bottom: 90px;
	position: relative;

	&:after{
		width: 10px;
		height: 10px;
		background-color: #333333;
		border-radius: 50%;
		position: absolute;
		left: 0px;
		bottom: -30px;
		content: '';
	}

	&:before{
		height: 2px;
		width: 200vw;
		right: 100%;
		bottom: -26px;
		content: '';
		position: absolute;
		background-color: #333333;
	}

	span{
		font-family: 'montserratextrabold';
	}
}
.conteudo__gerenciavel{
	margin-bottom: 60px;


	h2,h3{
		font-family: 'montserratextrabold';
		color: #333333;
		font-size: 36px;
		line-height: 40px;
		position: relative;
		margin-bottom: 46px;

		&:after{
			width: 210px;
			height: 4px;
			content: '';
			position: absolute;
			bottom: -22px;
			border-radius: 4px;
			left: 0px;
			background-color: currentColor;
		}
	}

	h4,h5{
		font-family: 'montserratextrabold';
		color: #333333;
		font-size: 24px;
		line-height: 28px;
		position: relative;
		margin-bottom: 30px;

		&:after{
			width: 210px;
			height: 4px;
			content: '';
			position: absolute;
			bottom: -16px;
			border-radius: 4px;
			left: 0px;
			background-color: currentColor;
		}
	}
	h6{
		font-family: 'montserratextrabold';
		color: #333333;
		font-size: 16px;
		line-height: 20px;
		position: relative;
		margin-bottom: 15px;
	}
	img{
		border-radius: 60px 0px 60px 0px;
	}
}
.row__numeros{
	padding-bottom: 80px;
	align-items:flex-start;

	.col-xl-4{
		padding-top: 20px;
	}

	.row{
		align-items: flex-start;
	}

	.numero__textos{
		.numero__titulo{
			font-family: 'montserratregular';
			font-size: 36px;
			line-height: 40px;
			color: #333333;
			margin-bottom: 5px;

			span{
				font-family: 'montserratextrabold';
			}
		}
	}
	.numero__sub{
		font-family: 'montserratregular';
		font-size: 18px;
		line-height: 22px;

		span{
			font-family: 'montserratextrabold';
		}
	}
}
.numero__card{
	text-align: right;
	position: relative;
	margin-bottom: 20px;
	max-width: 220px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	&:after{
		width: 170px;
		height: 136px;
		border-radius: 30px;
		background-color: #d9d9d9;
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: -1;
		content: '';
	}

	.numero__grande{
		display: inline-flex;
		align-items: flex-start;
		font-size: 90px;
		line-height: 80px;
		font-family: 'montserratextrabold';
		font-style: italic;

		span{
			font-family: 70px;
			line-height: 74px;
		}
	}
	.numero__grande__texto{
		font-size: 20px;
		line-height: 24px;
		font-family: 'montserratextrabold';
		font-style: italic;

		span{
			font-size: 34px;
			line-height: 36px;
		}
	}
}
.equipe__sobre{
	text-align: center;

	.titulo__premios{
		font-size: 30px;
		line-height: 34px;
		color: #333333;
		margin-bottom: 20px;

		span{
			font-family: 'montserratextrabold';
		}
	}
}
.segura__premios__owl{
	padding: 40px;
	border-top: 2px solid #333;
	border-bottom: 2px solid #333;

	.premios__owl.owl-carousel{
		.owl-dots{
			display: none;
		}
		a{
			display: block;
			max-width: 230px;
			width: 100%;
			margin-right: auto;
			margin-left: auto;

			img{
				border-radius: 15px;
			}
		}
		.owl-nav{
			button.owl-prev,
			button.owl-next{
				position: absolute;
				top: calc(50% - 14px);
				width: 28px;
				height: 28px;
				border: 2px solid #333333;
				color: #333333;
				font-size: 18px;
				line-height: 18px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.3s linear;

				&.disabled{
					pointer-events: none;
					opacity: 0.5;
				}

				&:hover{
					background-color: #333333;
					color: #fff;
				}
			}
			button.owl-prev{
				left: -40px;
			}
			button.owl-next{
				right: -40px;
			}
		}
	}
}
@include media-breakpoint-down (lg) {
	.numero__textos{
		margin-bottom: 30px;
	}
}
@include media-breakpoint-down (md) {
	.numero__card{
		margin-bottom: 60px;
	}
}
.unidade__card{
	display: flex;
	max-width: 290px;
	height: 350px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 50px;
	padding-top: 40px;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 40px;
	border-radius: 36px;
	padding-left: 32px;
	padding-right: 32px;
	color: #fff;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 2px solid #333;
		width: calc(100% + 20px);
		height: calc(100% + 20px);
		border-radius: 42px;
		opacity: 0;
	}

	.unidade__card__titulo{
		--linhas: 1;
		padding-bottom: 8px;
		position: relative;
		width: 100%;
		text-align: center;
		font-family: 'montserratextrabold';
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 16px;

		&:after{
			width: 100%;
			height: 2px;
			background-color: #fff;
			content: '';
			position: absolute;
			bottom: 0px;
			left: 0px;
		}
	}
}
.segura__estado__select{
	padding-left: 30px;
}
.investimento__custom{
	padding-top: 70px;

	.titulo__interna__quem{
		margin-bottom: 90px;
	}
}
.filtro__select{
	max-width: 300px;
	margin-bottom: 40px;

	select{
		position: fixed;
		left: -400vw;
	}
	.dropdown-menu{
		max-height: 200px;
		overflow-y: auto;
		padding: 5px 0px;
		width: 100%;
		border: none;
		box-shadow: 0px 0px 5px rgba(#000, 0.5);

		&::-webkit-scrollbar{
			width: 8px;
			border-radius: 4px;
		}

		&::-webkit-scrollbar-thumb{
			background-color: #888;
			border-radius: 4px;
		}
		&::-webkit-scrollbar-track{
			background-color: #333333;
			border-radius: 4px;
		}

		li{
			border-bottom: 2px solid #333333;
			padding: 8px 0px;
			font-family: 'montserratbold';
			font-size: 16px;
			line-height: 16px;
			margin: 0px 16px;
			position: relative;
			z-index: 20;
			transition: all 0.3s linear;
			cursor: pointer;

			&:after{
				width: calc(100% + 32px);
				left: -16px;
				top: -2px;
				height: calc(100% + 4px);
				position: absolute;
				z-index: -1;
				background-color: #333333;
				opacity: 0;
				transition: all 0.3s linear;
				position: absolute;
				content:'';
			}

			&.selected{
				color: #fff;

				&:after{
					opacity: 1;
				}
			}
		}
	}
	.output{
		max-width: 300px;
		border-radius: 16px;
		background-color: #d1d4df;
		font-family: 'montserratbold';
		font-size: 16px;
		line-height: 16px;
		color: #333333;
		padding: 10px;
	}
}
.unidade__info__item{
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;
}
.unidade__info__icon{
	font-size: 18px;
	line-height: 22px;
	margin-right: 8px;
}
.unidade__info__conteudo{
	font-size: 14px;
	line-height: 16px;
}
.unidade__card__info{
	margin-bottom: 30px;
}
.unidade__card__link{
	text-align: center;

	span{
		position: relative;
		padding: 6px 12px;
		overflow: hidden;
		border-radius: 14px;
		display: inline-block;
		z-index: 20;
		transition: all 0.3s linear;

		&:after{
			bottom: 0px;
			left: 12px;
			content: '';
			height: 3px;
			width: calc(100% - 24px);
			background-color: #fff;
			position: absolute;
			transition: all 0.3s linear;
			z-index: -1;
		}
	}
}
.estado__label{
	margin-bottom: 6px;
	display: inline-block;
}
.unidade__card{
	cursor: pointer;

	&:hover{
		.unidade__card__link{
			span{
				color: #333333;

				&:after{
					height: 100%;
					width: 100%;
					left: 0px;
				}
			}
		}
	}
	&.unidade__card--active{
		&:after{
			opacity: 1;
		}
		.unidade__card__link{
			span{
				color: #333333;

				&:after{
					height: 100%;
					width: 100%;
					left: 0px;
				}
			}
		}
	}
}
.nossos__assessores{
	.colaborador{
		text-align: center;

		.avatar{
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.nome{
			margin-bottom: 10px;
			font-family: 'montserratregular';
			font-size: 22px;
			line-height: 24px;
		}
	}
}
.titulo__assessores{
	font-size: 30px;
	line-height: 34px;
	color: #333;
	margin-bottom: 34px;

	span{
		font-family: 'montserratbold';
	}
}
