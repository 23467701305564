.btn-irtopo{
	display:none;
	padding:0;
	border:none;
	font-size:23px;
	width:(36 / 14) * 1em;
	height:(36 / 14) * 1em;
	position:fixed;
	bottom:1em;
	right:-100%;
	border-radius:100%;
	z-index:900;
	@include sombra($theme);
	border:2px solid darken($theme,20%) !important;

	.icon{
		animation:mouseBanner infinite alternate 2s;
	}

	@include media-breakpoint-up(lg){
		display:flex;
		align-items:center;
		flex-direction:column;
		justify-content:center;
	}

	&.fixo{
		right:1em;
	}

}

