.header-section{
	font-size:50px;
	color:$theme2;
	margin-bottom:18px;

	& > *{
		line-height:1;
		font-size:1em;
		margin-bottom:0;

		@include media-breakpoint-down(md){
			font-size:0.6em;
		}

	}

	.header-section__title{
		font-family: 'montserratblack';
	}

	.header-section__subtitle{
		font-family: 'montserratsemibold';
	}

}