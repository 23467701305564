.media-custom{
	font-size:12px;

	@include media-breakpoint-up(sm){
		display:flex;
		flex-wrap:wrap;
	}

	& + .media-custom{
		
		@include media-breakpoint-up(xl){
			position:relative;

			&:before{
				content:"";
				display:inline-block;
				width:1px;
				height:20px;
				margin-right:20px;
				background-color:#373737;
			}

		}

		a{

			& + a{

				&:before{
					content:"|";
					margin:0 2px;
				}

			}

		}

	}

}