.btn{

	&.btn--theme{
		@include button-variant($theme,$theme);
		color:$theme2;
	}

	&.btn--pill{
		border-radius: 2rem;
	}

	&.btn--outline-theme-2{
		background-color:transparent;
		border:1px solid $theme2;
		color:$theme2;
		position:relative;
		@include sombra($theme2);

		span{
			position:relative;
			z-index:60;
		}

		&:before{
			content:"";
			display:block;
			width:0;
			height:100%;
			position:absolute;
			background-color:$theme2;
			top:0;
			left:0;
			z-index:0;
			transition:width 0.5s, opacity 0.5s ease;
			opacity:0;
		}

		&:hover{
			color:#FFF !important;

			&:before{
				width:100%;
				opacity:1;
			}

		}

	}

	&.btn--padrao{
		font-size:20px;
		min-width:320px;
		border-width:4px;
		font-family: 'montserratbold';
		padding:16px 20px;
		border-radius:2px;

		@include media-breakpoint-down(md){
			display:block;
			min-width:auto;
		}

	}

	&.btn--padrao-2{
		font-size:12px;
		min-width:224px;
		border-width:4px;
		font-family: 'montserratbold';
		padding:16px 20px;
		border-radius:2px;
	}

	&.btn--responsivo{
		box-shadow:none;

		.bars{
			width:32px;
			height:5px;
			position:relative;
			display:inline-block;
			background-color:$theme2;

			&,
			&:before,
			&:after{
				transition:all 0.6s linear
			}

			&:before{
				top:calc(100% + 4px);
			}

			&:after{
				bottom:calc(100% + 4px);
			}


			@include beforeAfter{
				content:"";
				display:block;
				width:100%;
				position:absolute;
				height:100%;
				background-color:inherit;
				left:0;
			}

		}

	}


}