@import "_fontfaces";

html{
	scroll-behavior:smooth;
}

body{
	font-size:14px;
	color:$theme2;
	font-family: 'montserratregular';
}

@keyframes explodirmedia{

	0%{
		opacity:1;
		transform:scale(0);
	}

	100%{
		opacity:0;
		transform:scale(10);
	}

}

#app{
	position:relative;
	overflow:hidden;
}

.topo a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.col-lg-custom{
	@extend .col-lg-auto;

	@include media-breakpoint-up(lg){
		width:20%;
	}

}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

.atraso-5{
	animation-delay:0.8s;
}

lazy-image{
	background-color:transparent;
}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

.link{

	& + .link{

		&:before{
			content:"|";
			margin:0 4px;
		}

	}

}

// FONTS
.font--italic{
	font-style:italic;
}

.font--mnt-med{
	font-family: 'montserratmedium' !important;
}

.font--mnt-reg{
	font-family: 'montserratregular' !important;
}

.font--mnt-semi-bld{
	font-family: 'montserratsemibold' !important;
}

.font--mnt-blk{
	font-family: 'montserratblack' !important;
}

.font--mnt-bld{
	font-family: 'montserratbold' !important;
}

.font--fnt-16{
	font-size:16px !important;
}

.font--fnt-17{
	font-size:17px !important;
}

.font--fnt-30{
	font-size:30px !important;
}


[data-desligador="true"]{
	display:none;
	cursor:pointer;
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	z-index:950;
	left:0;
	background-color:rgba(#000,0.5);
}

// concerta erro meu (João) de sprites em outros projetos
// .icon{

// 	@each $icon,$value in $icons {
// 		&.#{$icon} {
// 			width: nth($value,3);
// 			height: nth($value,4);
// 			background-position: #{nth($value,1)} #{nth($value,2)};
// 		}
// 	}
// }

.line--height-1{
	line-height:1;
}

body{

	&.menu-active{

		.btn--responsivo{

			.bars{
				transform:rotate(45deg);

				@include beforeAfter{
					transform:rotate(-90deg);
				}

				&:before{
					top:0;
				}

				&:after{
					bottom:0;
				}

			}

		}

		.nav-content{
			left:0;
		}

	}


}
