.colaboradores {
	@extend .row;
	justify-content:center;
}

.colaborador{
	margin-bottom: 32px;
	min-width: 180px;

	.avatar{
		max-width: 155px;
		width:100%;
		margin-left:auto;
		margin-right:auto;
	    min-height: 155px;
	    margin-left: auto;
	    margin-right: auto;
	    margin-bottom: 10px;

	    img{
	    	border-radius: 100%;
	    	overflow: hidden;
	    }

	}

	.nome{
		font-weight: bold;
		font-size:16px;
	}

	.cargo{
		font-size: 13px;
	}

	.redes-colaboradores{
		font-size:19px;

		& > *{
			@include margin(0,5);
		}

	}

}
