.topo{
	
	@include media-breakpoint-up(xl){
		box-shadow:0 0 14px rgba(#999,0.5);
		position:relative;
		z-index:500;
	}

	.nav-content{

		@include media-breakpoint-down(lg){
			position:fixed;
			left:-230px;
			width:230px;
			transition:left 0.6s linear;
			height:100%;
			overflow:auto;
			z-index:1000;
			border-right:1px solid #000;
			background-color:#FFF;
		}

		@include media-breakpoint-up(xl){
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			@include make-container();
			@include make-container-max-widths();
		}

		.logo-desktop{
			margin-bottom:0;
			
			@include media-breakpoint-up(xl){
				max-width:362px;
				padding-top:10px;
			}

			@include media-breakpoint-down(lg){
				padding:30px 15px;
			}

		}

		.topo-principal{

			@include media-breakpoint-down(lg){
				display:flex;
				flex-direction:column;
			}

			& > *{
				width:100%;
			}

			@include media-breakpoint-up(xl){
				max-width:692px;
				flex-grow:1;
			}

			.bar-cima{
				background-color:$theme;
				position:relative;

				@include media-breakpoint-down(lg){
					order:2;
					text-align:center;
				}

				@include media-breakpoint-up(xl){
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					justify-content:space-between;

					&:before{
						content:"";
						display:block;
						position:absolute;
						left:100%;
						top:0;
						width:100vw;
						height:100%;
						background-color:inherit;
					}

					&:after{
						content:"";
						display:block;
						position:absolute;
						top:0;
						right:100%;
						border:23px solid transparent;
						border-right-color:$theme;
						border-top-color:$theme;
					}

				}

				.redes-sociais-area{
					background-color:$theme2;
					padding-left:16px;
					padding-right:16px;
					min-height:47px;

					@include media-breakpoint-down(lg){
						padding:20px 16px;
					}

					span{
						color:#D1D4DF;
						font-size:11px;
						display:inline-block;
						margin-bottom:10px;
							
						@include media-breakpoint-up(xl){
							margin-right:16px;
							margin-bottom:0;
						}

					}

					@include media-breakpoint-up(xl){
						display:flex;
						flex-wrap:wrap;
						align-items:center;
						justify-content:space-between;
						min-width:228px;
					}

				}

				.media-custom{

					@include media-breakpoint-down(lg){
						padding:10px 15px;
					}

				}

			}

			.bar-baixo{

				@include media-breakpoint-down(lg){
					order:1;
				}

			}

		}

	}

	&.fx{

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			z-index:600;
			width:100%;
			animation:fadeInDown 0.6s linear;
		}

	}

}